import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { HTMLContent } from '../components/common/Content'
import { PostContainer } from '../components/newsletter/PostContainer'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PostSummary } from '../components/newsletter/PostSummary'
import { PostList } from '../components/newsletter/PostList'

// eslint-disable-next-line
export const NewsletterPageTemplate = ({ posts }) => {
  const latestPost = posts.shift()
  return (
    <PostContainer>
      <PostList>
        {latestPost && <PostSummary {...latestPost} />}
        {posts.map((post) => (
          <PostSummary key={post.id} {...post} />
        ))}
      </PostList>
    </PostContainer>
  )
}

NewsletterPageTemplate.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object)
}

const NewsletterPage = ({ data }) => {
  const { allFeedNewsletter } = data
  return (
    <Layout>
      <NewsletterPageTemplate
        posts={allFeedNewsletter.nodes.map(({ enclosure, ...node }) => ({
          ...node,
          image: enclosure.url
        }))}
      />
    </Layout>
  )
}

NewsletterPage.propTypes = {}

export default NewsletterPage

export const NewsletterPageQuery = graphql`
  {
    allFeedNewsletter(sort: { fields: isoDate, order: DESC }) {
      nodes {
        contentSnippet
        link
        title
        creator
        isoDate
        enclosure {
          url
        }
        id
      }
    }
  }
`
